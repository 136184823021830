import React from 'react'
import PropTypes from 'prop-types'
import Base from '../components/Base'
import Meta from '../components/Meta'

const FourOFour = props => (
  <Base>
    <Meta location={props.location} pageTitle="404" />
    <h1>404</h1>
    <p>The page you were looking for was not found, sorry about that.</p>
    <p>Try to search the thing you were looking for.</p>
  </Base>
)

FourOFour.propTypes = {
  location: PropTypes.object
}

export default FourOFour
